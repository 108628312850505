import { Box, Button } from "@enerbit/base";
import { navigateToUrl } from "single-spa";

interface Props {
  openRefustigateModal: () => void;
  openRestoreDataModal: () => void;
}

const MeterDetailActions = ({ openRefustigateModal, openRestoreDataModal }: Props) => {
  const goToOrderServices = () => {
    navigateToUrl("/#/orders");
  };


  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {/* <Button
          onClick={goToOrderServices}
          sx={{ width: "200px" }}
          variant='contained'
          color='secondary'>
          Generar OS
        </Button> */}
        <Button
          sx={{ width: "200px" }}
          variant='contained'
          color='primary'
          onClick={openRefustigateModal}>
          Refustigar
        </Button>
        <Button
          sx={{ width: "200px", color: "#fff" }}
          variant='contained'
          color='success'
          onClick={openRestoreDataModal}>
          Recuperar datos
        </Button>
      </Box>
    </Box>
  );
};

export default MeterDetailActions;
